<template>
  <div class="list-info">
    <top-bar :title="'房屋列表'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入房屋户或者房主名搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
        <!-- 筛选栏 -->
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="communityShow = !communityShow" :style="{color:communityShow?'#387FF5':'#666666'}">{{communityValue || '本街道'}}
            <img :src="require(`@/assets/img/${communityShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="liveShow = !liveShow" :style="{color:liveShow?'#387FF5':'#666666'}">{{liveValue || '居住信息'}}
            <img :src="require(`@/assets/img/${liveShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>

        <van-col span="6">
          <p @click="attrShow = !attrShow" :style="{color:attrShow?'#387FF5':'#666666'}">{{attrValue || '房屋属性'}}
            <img :src="require(`@/assets/img/${attrShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>

        <van-col span="6">
          <p style="color: #666666" @click="moreCancel">重置</p>
        </van-col>
      </van-row>
    </div>
    <!-- 弹出框 -->
    <van-popup v-model="liveShow" round position="bottom" @close="liveChange">
      <van-cascader v-model="liveCascaderValue" title="居住信息" :options="liveList" @close="liveShow = false"
                    :field-names="fieldNames" @change="liveFinish"/>
    </van-popup>
    <van-popup v-model="communityShow" round position="bottom">
      <van-picker show-toolbar :columns="communityList" value-key="label" @cancel="communityShow = !communityShow" @confirm="communityConfirm"/>
    </van-popup>
    <van-popup v-model="attrShow" round position="bottom">
      <van-picker show-toolbar :columns="attrList" value-key="label" @cancel="attrShow = !attrShow" @confirm="attrConfirm"/>
    </van-popup>
<!--    <van-popup v-model="moreShow" round position="bottom">-->
<!--      <van-picker show-toolbar :columns="moreList" value-key="label" @cancel="moreShow = !moreShow" @confirm="moreConfirm"/>-->
<!--    </van-popup>-->

    <div class="addBtn" @click="goAdd">
        <img src="@/assets/img/add.png" alt="">
    </div>
    <!-- 列表 -->
     <div class="cont">
      <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id,item.liveNum,item.migrantNum)">
            <div class="title">
              <div class="title-left">
              {{(item.userName || '无房主') + ' | ' + (item.userMobile?item.userMobileStr:'无联系方式')}}
               </div>
               <div class="title-right">
                 <a :href="'tel:'+item.userMobile" @click.stop="" v-if="item.userMobile">
                   <img src="@/assets/img/phone.png" alt="" class="tel"/></a>
              </div>
              </div>
            <div class="content">
              <van-image :src="require(`@/assets/img/${item.liveNum==0?'house-empty':item.useType==2?'house-rent':'house-live'}.png`)" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.fullName}}</div>
                <div class="right-text" :style="{color:item.status==10?'#FE5E5E':'#3E7EFE'}" style="right:0">{{item.communityName}}</div>
                <div class="bottom-text">居住人数<span style="color:#387FF5"> {{item.liveNum}} </span>人 流动人口<span style="color:#ffa93a"> {{item.migrantNum}} </span>人</div>
              </div>
              <van-icon name="arrow" color="#666" class="bottom-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDate, desensitization} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
 import { mapMutations} from "vuex"
import { getBelongCommunity} from '@/utils/common'
export default {
  name: 'house',
  components:{
    topBar
  },
  data(){
    return{
      totalCount:0,
      page: 0,
      limit: 10,
      orgId: 0,
      submitData: {
          community: '',
          subarea: '',
          buildingId: '',
          unitId: '',
          houseId: '',
          useType: '',
          moreType:'',
        },
        liveValue: '',
        communityValue: '',
        attrValue: '',
        moreValue:'',
        searchValue: '',
        finished: false,
        loading: false,
        liveShow: false,
        communityShow: false,
        attrShow: false,
        moreShow:false,
        pullLoading: false,
        liveList: [],
        communityList: [],
        attrList: [],
        moreList: [
          {id:1,}
        ],
        dataList: [
          // {id:1,liveNum:'333',migrantNum:'555',userName:'李强',userMobileStr:'123456',userMobile:1234566}
        ],
        fieldNames:{
          text:'label',
          value:'value',
          children:'children'
        },
        liveCascaderValue: ''
    }
  },
   computed: {
      desensitization (val) {
        return desensitization(val)
      }
    },

  methods:{
    ...mapMutations,
      getInfo(id,liveNum,migrantNum){
       this.$store.commit('setHouseId', id)
       this.$store.commit('setMigrantNum', migrantNum)
       this.$router.push('/house-info')
    },
    // 获取数据列表
    getDataList(){
      this.page++
      this.$toast.loading({duration:0,message:'加载中...',forbidClick:true})
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          wgOrgId: this.submitData.community,
          // gridId: this.submitData.gridId,
          subarea: this.submitData.subarea,
          buildingId: this.submitData.buildingId,
          unitId: this.submitData.unitId,
          houseId: this.submitData.houseId,
          useType: this.submitData.useType,
          searchValue: this.searchValue,

        })
        }).then(({data})=> {
            if (data && data.code == 0) {
              this.pullLoading = false
                if (this.dataList.length == data.page.totalCount) {
                    this.finished = true
                }
                data.page.list.forEach(item=> {
                  item.userMobileStr = desensitization(item.userMobile)
                  let url =''
                  if(item.liveNum==0) {
                    url = getImageStream('files/wx/images/content/kong.png')
                  }else if(item.useType==2) {
                    url = getImageStream('files/wx/images/content/zu.png')
                  }else{
                    url = getImageStream('files/wx/images/content/zhu.png')
                  }
                    item["url"] = url
                })
                this.totalCount = data.page.totalCount
                this.dataList = this.dataList.concat(data.page.list)
                // 加载状态结束
                this.loading = false;
                this.$toast.clear()
            }
            this.$toast.clear()
    },err=> {this.$toast.clear()})

    },
    init (){
      // 获取居住信息列表
        getBelongCommunity(this.$orgId, -1, (communityList) => {
          this.communityList = communityList
          this.communityList.unshift({
            label: '本街道',
            value: Number(this.orgId)
          })
        })
        getBelongCommunity(this.$orgId, -1, (communityList) => {
          this.liveList = communityList
        })
          // 获取属性列表
          this.$http({
            url: this.$http.adornUrl('/wxapp/sys/dict/virtual/useType'),
            method: 'get'
          }).then(({data})=> {
            if (data && data.code == 0) {
              this.attrList = data.useTypes
              this.attrList.unshift({label:'所有',value:''})
            }
          },err=> {this.$toast.console.error({message:err})})
          this.getDataList();
          // // 获取更多的列表
          // this.$http({
          //   url: this.$http.adornUrl(''),
          //   method: 'get'
          // }).then(({data}) =>{
          //   if(data.code == 0){
          //     // this.moreList = data.moreType
          //     this.moreList.unshift({label:'w123',value:''})
          //   }
          // },err=> {this.$toast.console.error({message:err})})
        },


     // 居住信息
    liveChange(){
      this.page = 0
      this.finished = false
      this.dataList = []
      this.getDataList()
      this.liveShow = false
    },
    liveFinish(value){
      let options = value.selectedOptions
      let right = options[options.length-1]
      let orgId = options[options.length-1].value
      this.liveValue = options[options.length-1].label
      if(right.depth !== 4 && right.parameter == null) {
        getBelongCommunity(orgId, value.tabIndex, (communityList) => {
          options[value.tabIndex].children = communityList
        })
      }
      this.submitData.community = ''
      this.submitData.subarea = ''
      this.submitData.buildingId = ''
      this.submitData.unitId = ''
      this.submitData.houseId = ''
      if(options && options.length >= 1){
        if (value.tabIndex == 0) {
          this.submitData.community = right.value+""
        }
        if (right.depth == 1 && value.tabIndex !== 0) {
          this.submitData.subarea = right.value+""
        }
        if (right.depth == 2) {
          this.submitData.buildingId = right.value+""
        }
        if (right.depth == 3) {
          this.submitData.unitId = right.value+""
        }
        if (right.depth == 4){
          this.submitData.houseId = right.value+""
        }
        this.submitData.community = this.submitData.community
        this.submitData.subarea = this.submitData.subarea
        this.submitData.buildingId = this.submitData.buildingId
        this.submitData.unitId = this.submitData.unitId
        this.submitData.houseId = this.submitData.houseId
      }else{
        this.submitData.community = ''
        this.submitData.subarea = ""
        this.submitData.buildingId = ""
        this.submitData.unitId = ""
        this.submitData.houseId = ""
      }
      if (right.parameter !== null) {
        this.liveShow = false
        this.page = 0
        this.finished = false
        this.dataList = []
        this.getDataList()
      }
    },

    communityConfirm(value){
      this.page = 0
      this.dataList = []
      this.communityValue = value.label
      this.submitData.community = value.value
      this.getDataList()
      this.communityShow = false
    },
    attrConfirm(value){
      this.page = 0
      this.dataList = []
      this.attrValue = value.label
      this.submitData.useType = value.value
      this.getDataList()
      this.attrShow = false
    },
    moreConfirm(value){
      this.page = 0
      this.dataList = []
      this.moreValue = value.label
      this.submitData.moreType = value.value
      this.getDataList()
      this.attrShow = false
    },
    moreCancel () {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.searchValue = ''
      this.communityValue = '本街道'
      this.liveValue = '居住信息'
      this.attrValue = '房屋属性'
      this.submitData.subarea = ''
      this.submitData.buildingId = ''
      this.submitData.unitId = ''
      this.submitData.houseId = ''
      this.submitData.useType = ''
      this.getDataList()
    },
    // 搜索
    onCancel(){
      this.page = 0
      this.searchValue = ''
      this.dataList = []
      this.getDataList()
    },
    onSearch(){
       this.page = 0
       this.dataList = []
       this.getDataList()
    },
    // 添加
    goAdd(){
      this.$router.push('/house-add')
    },

    // 刷新
     onPullDownRefresh(e) {
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },

  },
  created (){
   this.$toast.loading({
          duration: 0,
          message: '加载中...',
          forbidClick: true,
      });
    this.orgId = this.$globalData.userInfo.orgId
    // this.init()
    this.page = 0;
    this.dataList = [];
    this.init();
  },
  activated () {
    let globalSearchValue = this.$route.query
    if (globalSearchValue.attrValue) {
      this.attrValue = globalSearchValue.attrValue
    } else {
      this.attrValue = ''
    }
    if (globalSearchValue.useType) {
      this.submitData.useType = globalSearchValue.useType
    } else {
      this.submitData.useType = ''
    }
    if (globalSearchValue.orgId && this.$globalData.orgId != globalSearchValue.orgId) {
      this.submitData.community = globalSearchValue.orgId
    } else {
      this.submitData.community = ''
    }
    if (globalSearchValue.unitId) {
      this.submitData.unitId = globalSearchValue.unitId
    } else if (globalSearchValue.buildingIds) {
      this.submitData.buildingId = globalSearchValue.buildingIds
    } else {
      this.submitData.unitId = ''
      this.submitData.buildingId = ''
    }
    if (globalSearchValue.obuhName) {
      this.liveValue = globalSearchValue.obuhName
    } else {
      this.liveValue = ''
    }
    if (JSON.stringify(globalSearchValue) != '{}') {
      this.page = 0;
      this.dataList = [];
      this.init();
    }
  }
}
</script>

<style scoped>

</style>
